<template>
  <div class="main">
    <div class="logoContainer">
      <img class="logo" src="../assets/logo.svg"/>
    </div>
    <div class="footer d-flex justify-center" style="margin-bottom: 25px">
      <v-btn outlined color="#C0B596" @click="newGuestsClick()">New Extension Guests</v-btn>
    </div>
    <div class="footer d-flex justify-center" style="margin-bottom: 25px">
      <v-btn outlined color="#C0B596" @click="bookNowClick()">EXISTING GUESTS</v-btn>
    </div>

    <div class="footer d-flex justify-center" style="margin-bottom: 25px">
      <v-btn outlined color="#C0B596" @click="textBtnClick()">TEXT US</v-btn>
    </div>

    <v-container>
      <!-- <v-img style="margin: 10px auto 10px auto;" max-width="500px" contain src="../assets/homepage/hairSpecialFrame.jpeg"></v-img> -->
      <v-img style="margin: auto;" max-width="500px" contain src="../assets/homepage/chair.jpeg"></v-img>
    </v-container>

    <!-- <v-dialog :absolute="false" :value="overlay" transition="scale-transition" width="500px">
      <v-container fluid>
          <v-card class="serviceCard" color="#EEEEEE" outlined elevation="4">
            <div class="d-flex flex-row justify-space-between">
              <v-card-title>We Moved!</v-card-title>
              <div style="cursor: pointer; text-align: right" @click="overlay=false">
                <v-icon style="color: black;">mdi-close-circle-outline</v-icon>
              </div>
            </div>
            <v-card-text>You can now find Jess and Ingrid inside Haven Salon Studios <br>
            Located at <a href="https://maps.app.goo.gl/YohZxsuEQkCcKtzN6" target="_blank">315 Rt 35 Suite #122, Eatontown, NJ 07724</a></v-card-text>
          </v-card>
      </v-container>
    </v-dialog> -->
  </div>
</template>

<script>
  export default {
    name: 'Home',
    components: {
    },
    data: () => ({
      overlay: true
    }),
    methods: {
      bookNowClick () {
        window.open('https://www.vagaro.com/houseoffringe/book-now', '_blank')
      },
      newGuestsClick () {
        window.open('https://form.jotform.com/232184265052148', '_blank')
      },
      textBtnClick () {
        window.open('sms:9089076062', '_blank')
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .main {
    /*background-color: #000000;*/
    height: 100%;
  }
  .logo {
    min-width: 50%;
  }
  .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 374px) {
    .mostScreens {
      display: none !important;
    }
  }
  @media screen and (min-width: 375px) {
    .xxsScreens {
      display: none !important;
    }
  }

  .bottomLine {
    margin-top: -25px;
  }
</style>